import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Main from "../Layout/Main";
import Portfolio from "../Layout/Portfolio";
import AboutMe from "../Layout/AboutMe";

import Ace from "../Detail/Ace";
import Shop from "../Detail/Shop";
import Sonjabee from "../Detail/Sonjabee";
import Bingli from "../Detail/Bingli";
import ItSchool from "../Detail/ItSchool";
import Yourartist from "../Detail/Yourartist";
import SejongSafer from "../Detail/SejongSafer";
import Kmf from "../Detail/Kmf";
import FutsalDaejeon from "../Detail/FutsalDaejeon";
import Kycu from "../Detail/Kycu";
import Petn from "../Detail/Petn";
import Classys from "../Detail/Classys";
import Xerox from "../Detail/Xerox";
import Ndps from "../Detail/Ndps";
import Ongime from "../Detail/Ongime";
import KotteScan from "../Detail/KotteScan";
import CodexBridge from "../Detail/CodexBridge";
import Ksel from "../Detail/Ksel";
import Dystec from "../Detail/Dystec";
import Ctbio from "../Detail/Ctbio";
import ExternalRedirect from "./ExternalRedirect";

const RouterSet = (props) => (
  <Switch>
    <Route
      exact
      path="/"
      render={() => (
        <Main
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Main"
      render={() => (
        <Main
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/AboutMe"
      render={() => (
        <AboutMe
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio"
      render={() => (
        <Portfolio
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />

    <Route
      exact
      path="/Portfolio/Ace"
      render={() => (
        <Ace
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Shop"
      render={() => (
        <Shop
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Sonjabee"
      render={() => (
        <Sonjabee
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Bingli"
      render={() => (
        <Bingli
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/ItSchool"
      render={() => (
        <ItSchool
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Yourartist"
      render={() => (
        <Yourartist
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/SejongSafer"
      render={() => (
        <SejongSafer
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Kmf"
      render={() => (
        <Kmf
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Kycu"
      render={() => (
        <Kycu
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/FutsalDaejeon"
      render={() => (
        <FutsalDaejeon
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Petn"
      render={() => (
        <Petn
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Classys"
      render={() => (
        <Classys
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Xerox"
      render={() => (
        <Xerox
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Ndps"
      render={() => (
        <Ndps
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/Ongime"
      render={() => (
        <Ongime
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/KotteScan"
      render={() => (
        <KotteScan
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route
      exact
      path="/Portfolio/CodexBridge"
      render={() => (
        <CodexBridge
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
     <Route
      exact
      path="/Portfolio/Ksel"
      render={() => (
        <Ksel
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
      <Route
      exact
      path="/Portfolio/Dystec"
      render={() => (
        <Dystec
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
      <Route
      exact
      path="/Portfolio/Ctbio"
      render={() => (
        <Ctbio
          setColorData={props.setColorData}
          setHeaderClassName={props.setHeaderClassName}
        />
      )}
    />
    <Route path="*">
      <ExternalRedirect to="https://0u0.kr/info.php" /> 
    </Route>
  </Switch>
);
export default RouterSet;
